/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import {
  createRef, useCallback, useEffect, useState,
} from 'react'
import { useDispatch /* , useSelector */ } from 'react-redux'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import useDimensions from 'Hooks/useDimensions'
import Videos from 'components/Videos/Videos'
import dynamic from 'next/dynamic'
// import VideoOverlay from 'components/VideoOverlay/VideoOverlay'
import HeroText from 'components/HeroText/HeroText'
import Ambition from 'components/Ambition/Ambition'
import Media from 'components/Media/Media'
import TestimonialsCarousel from 'components/TestimonialsCarousel/TestimonialsCarousel'
import Template from '../containers/Template/Template'
import CategoryColumns from '../components/CategoryColumns/CategoryColumns'
import {
  setSearchPageSortOrder, setSearchResultPageCounterAction,
} from '../store/actions/main'
import {
  DEFAULT, globalData, shuffle,
} from '../helper'
import fs from "fs"

const ProgramIconsMarquee = dynamic(() => import('../components/ProgramIconsMarquee/ProgramIconsMarquee'))

SwiperCore.use([Navigation, Pagination])
const swiperref = createRef()

const videos = {
  desktop: shuffle([
    {
      mp4: '/videos/gaming-killen__desktop.mp4',
      webm: '/videos/gaming-killen__desktop.webm',
      ref: createRef(),
      poster: '/images/startpage/webp/gaming-killen__desktop.webp',
    },
    {
      mp4: '/videos/grill-pappor__desktop.mp4',
      webm: '/videos/grill-pappor__desktop.webm',
      ref: createRef(),
      poster: '/images/startpage/webp/grill-pappor__desktop.webp',
    },
    {
      mp4: '/videos/yoga-mamman__desktop.mp4',
      webm: '/videos/yoga-mamman__desktop.webm',
      ref: createRef(),
      poster: '/images/startpage/webp/yoga-mamman__desktop.webp',
    },
  ]),
  mobile: /* shuffle */([
    {
      mp4: '/videos/gaming-killen__mobile.mp4',
      webm: '/videos/gaming-killen__mobile.webm',
      ref: createRef(),
      // poster: '/images/startpage/webp/gaming-killen__mobile.webp',
    },
    {
      mp4: '/videos/yoga-mamman__mobile.mp4',
      webm: '/videos/yoga-mamman__mobile.webm',
      ref: createRef(),
      // poster: '/images/startpage/webp/yoga-mamman__mobile.webp',
    },
  ]),
}

const getVideos = (isMobile) => (isMobile ? videos.mobile : videos.desktop)
const getNextActiveIndex = (visibleVideos, activeIndex) => (activeIndex === visibleVideos.length - 1 ? 0 : activeIndex + 1)

const Index = ({
  /* categories, */ seoMenu, indexed, followed, url, popularSearches, /* salesPeriods, */
}) => {
  // const searchModalInputValue = useSelector((state) => state.main.searchModalInputValue)
  const [isActive, setActive] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const dispatch = useDispatch()
  const { isMobile } = useDimensions({ mobileBreakPoint: 800, throttleMs: 150 })
  const { isMobile: subNavigationIsMobile } = useDimensions({ mobileBreakPoint: 1401, throttleMs: 150 })
  const visibleVideos = getVideos(isMobile)

  const showVideo = useCallback(() => {
    setActive(true)
    try {
      visibleVideos[activeIndex].ref.current.muted = false
    } catch (error) {
      console.error(error.message)
    }
  }, [activeIndex, visibleVideos])

  const hideVideo = useCallback(() => {
    setActive(false)
    try {
      visibleVideos[activeIndex].ref.current.muted = true
    } catch (error) {
      console.error(error.message)
    }
  }, [activeIndex, visibleVideos])

  useEffect(() => {
    dispatch(setSearchResultPageCounterAction(0))
    dispatch(setSearchPageSortOrder(DEFAULT))
  }, [dispatch])

  const onActiveIndexChange = useCallback(({ activeIndex }) => {
    visibleVideos.forEach((video, index) => {
      if (index !== activeIndex) {
        try {
          if (!video.ref.current) {
            return
          }
          video.ref.current.currentTime = 0
          video.ref.current?.pause()
          video.ref.current.muted = true
        } catch (error) {
          console.error(error.message)
        }
        return
      }
      try {
        if (!video.ref.current) {
          return
        }
        video.ref.current.currentTime = 0
        video.ref.current.muted = !isActive
        video.ref.current.play()
      } catch (error) {
        console.error(error.message)
      }
    })
    setActiveIndex(activeIndex)
  }, [isActive, visibleVideos])

  useEffect(() => () => {
    videos.desktop.forEach((video) => {
      try {
        if (!video.ref.current) {
          return
        }
        video.ref.current?.pause()
        video.ref.current.currentTime = 0
      } catch (error) {
        console.error(error.message)
      }
    })
    videos.mobile.forEach((video) => {
      try {
        if (!video.ref.current) {
          return
        }
        video.ref.current?.pause()
        video.ref.current.currentTime = 0
      } catch (error) {
        console.error(error.message)
      }
    })
  }, [])

  const slideToNextVideo = useCallback(() => {
    const nextActiveIndex = getNextActiveIndex(visibleVideos, activeIndex)
    try {
      swiperref.current.swiper.slideTo(nextActiveIndex)
    } catch (error) {
      console.error(error.message)
    }
  }, [activeIndex, visibleVideos])

  return (
    <Template
      title="Jiroy.com - Smart shopping!"
      description="Välkommen till Sveriges nya shoppingsajt! Med över 350 butiker på ett och samma ställe kan du superenkelt shoppa bland flera miljoner produkter!"
      twitterCard="summary_large_image"
      indexed={indexed}
      followed={followed}
      url={url}
      canonical={url}
      className="home"
      // categories={categories}
      seoMenu={seoMenu}
      popularSearches={popularSearches}
      // salesPeriods={salesPeriods}
      structuredData={{
        organization: true,
        webSite: true,
      }}
      irSiteVerification
      copyrightedSiteVerification
    >
      <main>
        <header id="hero" className={`${isActive ? 'show-video' : ''}`}>
          <HeroText />
          <Videos swiperref={swiperref} isMobile={isMobile} slideToNextVideo={slideToNextVideo} visibleVideos={visibleVideos} onActiveIndexChange={onActiveIndexChange} />

          <div className="video-button-wrapper">
            {isActive && <button onClick={hideVideo} label="button" type="button">Tillbaka</button>}
            {!isActive && (<button className="text-btn" onClick={showVideo} label="button" type="button">Spela video</button>)}
          </div>
        </header>
        <ProgramIconsMarquee />

        <CategoryColumns seoMenu={seoMenu} isMobile={subNavigationIsMobile} />
        <Ambition />
        <Media />
        <TestimonialsCarousel />
      </main>
    </Template>
  )
}

const getGlobalDataFromDisk = async (path) => {
  const mockDataExists = fs.existsSync(path)
  if (!mockDataExists) {
    const props = await globalData()
    fs.writeFileSync(path, JSON.stringify(props, null, 2))
  }

  const mockDataString = fs.readFileSync(path, "utf-8")
  const response = JSON.parse(mockDataString)

  return response
}

export const getStaticProps = async () => {
  const {
    popularSearches,
    // categories,
    // salesPeriods,
    amountOfProducts,
    baseUrl,
    indexed,
    followed,
    seoMenu,
  } = await getGlobalDataFromDisk("mock-data.json")

  return {
    props: {
      url: baseUrl.replace('/se', ''),
      indexed,
      followed,
      // categories,
      seoMenu,
      popularSearches,
      amountOfProducts: amountOfProducts || 0,
      // salesPeriods,
    },
  }
}
export default Index
