import { Swiper, SwiperSlide } from 'swiper/react'

const Videos = ({
  isMobile, slideToNextVideo, visibleVideos, onActiveIndexChange, swiperref,
}) => {
  if (isMobile) {
    return (
      <div className="video-swiper">
        <div className="swiper-overlay" />
        <video onEnded={slideToNextVideo} ref={visibleVideos[0].ref} style={{ width: '100%', height: '100%' }} autoPlay muted playsInline>
          <source src={visibleVideos[0].webm} type="video/webm" />
          <source src={visibleVideos[0].mp4} type="video/mp4" />
          Din webbläsare stöder inte inbäddade videor.
        </video>
      </div>
    )
  }

  return (
    <Swiper
      ref={swiperref}
      data-testid="video-swiper"
      className="video-swiper"
      spaceBetween={0}
      slidesPerView="1"
      pagination={{
        clickable: true,
      }}
      onActiveIndexChange={onActiveIndexChange}
      navigation={{
        prevEl: '.prev',
        nextEl: '.next',
      }}
    >
      <button type="submit" className="prev" title="Gå till föregående video">
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </button>

      <button type="submit" className="next" title="Gå till nästa video">
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </button>

      <div className="swiper-overlay" />
      {visibleVideos.map((x) => (
        <SwiperSlide key={x.mp4}>
          <video
            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
            onEnded={slideToNextVideo}
            ref={x.ref}
            poster={x.poster}
            style={{ width: '100%', height: '100%' }}
            autoPlay
            muted
            playsInline
          >
            <source src={x.webm} type="video/webm" />
            <source src={x.mp4} type="video/mp4" />
            Din webbläsare stöder inte inbäddade videor.
          </video>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Videos
