import { memo, useState } from 'react'
import Link from 'next/link'
import kebabCase from 'lodash.kebabcase'
import SkeletonImage from 'components/SkeletonImage/SkeletonImage'
import { categorySingleWord } from 'helper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwitchLink from 'components/SwitchLink/SwitchLink'

const getWord = (key) => categorySingleWord[kebabCase(key)]

const CategoryColumns = ({ seoMenu = [], isMobile }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  if (!seoMenu || seoMenu.length === 0) {
    return null
  }

  const onActiveIndexChange = ({ activeIndex: _activeIndex }) => {
    setActiveIndex(_activeIndex)
  }

  if (isMobile) {
    return (
      <nav className="categories-wrapper__mobile">
        <Swiper
          spaceBetween={30}
          freeMode
          slidesPerView={2.2}
          breakpoints={{
            275: {
              slidesPerView: 2.2,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 3.2,
              spaceBetween: 16,
            },
            896: {
              slidesPerView: 4.2,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 5.2,
              spaceBetween: 16,
            },
          }}
          onActiveIndexChange={onActiveIndexChange}
        >
          {seoMenu.map(({ name, href, _id }, i) => {
            const loading = i <= activeIndex + 2 ? 'eager' : undefined
            const priority = i <= activeIndex + 2 ? true : undefined
            return (
              <SwiperSlide key={_id}>
                <Link href={href}>
                  <a>
                    <SkeletonImage
                      loading={loading}
                      priority={priority}
                      height={130}
                      width={230}
                      src={`/images/categories/${kebabCase(name)}.webp`}
                      alt={name}
                    />
                    <div className="categories-wrapper__desktop__title">{getWord(name)}</div>
                  </a>
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </nav>
    )
  }

  return (
    <nav className="categories-wrapper__desktop">
      <ul>
        {seoMenu.map(({ name, href, _id }) => (
          <li
            key={_id}
            className="categories-wrapper__desktop__item"
          >
            <SwitchLink href={href}>
              <SkeletonImage
                height={87}
                width={154}
                src={`/images/categories/${kebabCase(name)}.webp`}
                alt={name}
              />
              <div className="categories-wrapper__desktop__title">{getWord(name)}</div>
            </SwitchLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default memo(CategoryColumns)
