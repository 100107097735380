/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
const Location = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fillRule="evenodd"
  >
    <g transform="matrix(1,0,0,1,-48,-48)">
      <g>
        <path d="M72,57C68.137,57 65,60.137 65,64C65,67.863 68.137,71 72,71C75.863,71 79,67.863 79,64C79,60.137 75.863,57 72,57ZM72,59C74.76,59 77,61.24 77,64C77,66.76 74.76,69 72,69C69.24,69 67,66.76 67,64C67,61.24 69.24,59 72,59Z" />
        <path d="M64.158,77C63.764,76.622 63.348,76.266 62.913,75.932L62.916,75.934C59.321,73.193 57,68.865 57,64C57,55.721 63.721,49 72,49C80.279,49 87,55.721 87,64C87,68.865 84.68,73.191 81.081,75.926C80.644,76.262 80.226,76.62 79.83,77L84,77C85.291,77 86.438,77.826 86.846,79.051C86.846,79.051 90.846,91.051 90.846,91.051C91.151,91.966 90.998,92.972 90.434,93.754C89.87,94.536 88.964,95 88,95C88,95 56,95 56,95C55.036,95 54.13,94.536 53.566,93.754C53.002,92.972 52.849,91.966 53.154,91.051C53.154,91.051 57.154,79.051 57.154,79.051C57.562,77.826 58.709,77 60,77L64.158,77ZM78.083,79C77.598,79.667 77.167,80.375 76.795,81.118C76.795,81.118 74.683,85.342 74.683,85.342C74.175,86.358 73.136,87 72,87C70.864,87 69.825,86.358 69.317,85.342C69.317,85.342 68.25,83.209 67.198,81.125C67.198,81.124 67.197,81.123 67.197,81.122C66.825,80.378 66.393,79.668 65.906,79L60,79C59.57,79 59.187,79.275 59.051,79.684C59.051,79.684 55.051,91.684 55.051,91.684C54.95,91.989 55.001,92.324 55.189,92.585C55.377,92.845 55.679,93 56,93L88,93C88.321,93 88.623,92.845 88.811,92.585C88.999,92.324 89.05,91.989 88.949,91.684L84.949,79.684C84.813,79.275 84.43,79 84,79L78.083,79ZM64.131,74.345C64.13,74.345 64.129,74.344 64.129,74.344C61.013,71.968 59,68.217 59,64C59,56.825 64.825,51 72,51C79.175,51 85,56.825 85,64C85,68.216 82.988,71.966 79.868,74.336C79.867,74.337 79.865,74.338 79.864,74.339C77.82,75.907 76.159,77.919 75.006,80.223L72.894,84.447C72.725,84.786 72.379,85 72,85C71.621,85 71.275,84.786 71.106,84.447C71.106,84.447 70.038,82.311 68.984,80.224C67.832,77.922 66.173,75.912 64.131,74.345Z" />
      </g>
    </g>
  </svg>
)

export default Location
