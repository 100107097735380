/* eslint-disable max-len */
import LinkWrapper from 'components/LinkWrapper/LinkWrapper'

const parts = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" id="breakit" viewBox="0 0 1074.71 254.95">
        <title>Breakit</title>
        <polygon className="cls-1" points="913.76 47.06 904.77 88.24 948.49 88.24 922.4 207.33 969.01 207.33 995.1 88.24 1039.89 88.24 1048.87 47.06 913.76 47.06" />
        <polygon className="cls-1" points="843.42 47.06 781.66 47.06 729.87 102.49 742.3 47.06 693.95 47.06 658.71 207.33 706.41 207.33 716.39 162.59 729.83 149.06 754.3 207.44 810.19 207.44 766.66 121.53 843.42 47.06" />
        <polygon className="cls-1" points="818.77 207.33 866.7 207.33 902.77 47.06 854.84 47.06 818.77 207.33" />
        <path className="cls-1" d="M186.24,111.74c5.5-7.09,8.09-16.15,8.53-25.79,.51-11.2-5.26-22.65-15.59-30.15-9.63-6.99-20.84-8.74-36.56-8.74H60.89L25.83,207.33H117.27c18.61,0,35.66-1.61,46.91-12.03,15.88-14.71,16.69-23.11,17.38-38.47,.29-6.46-2.26-12.34-5.65-17.38-3.39-5.05-9.68-8.77-16.32-11.59,16.76-5,21.15-9.03,26.65-16.12Zm-51.5,42.93c0,5.42-2.23,10.04-9.21,11.82-3.58,.91-7.36,.77-11.01,.77h-31.99l4.35-21.38s32.33,0,32.35,0c4.57,0,10.32-.04,13.61,3.7,1.33,1.52,1.9,3.28,1.9,5.08Zm9.28-59.49c0,5.06-3.4,9.82-8.51,10.7-2.96,.51-5.96,0-8.93,0h-31.1l5.06-18.76s32.3,0,32.45,0c2.58,0,5.39,.18,7.66,1.54,2.3,1.37,3.38,3.91,3.38,6.52Z" />
        <path className="cls-1" d="M335.81,61.05c-9.77-8.7-22.53-13.05-38.27-13.05h-78.16l-34.48,159.88h46.34l12.07-51.29h20.31l11.04,51.29h51.97s-15.44-54.17-15.44-54.18c1.11-.24,6.59-3.08,7.04-3.34,6.65-3.88,12.51-7.9,17.46-13.86,4.12-4.97,7.47-10.57,9.98-16.51,4.93-11.71,6.7-25.11,3.36-37.5-2.23-8.27-6.81-15.75-13.21-21.45Zm-39.7,50.03c-4.34,3.39-10.32,3.76-15.68,3.76h-27.42l5.05-25.76h29.55c4.85,0,10.29,2.1,12.61,6.63,2.7,5.27,.37,11.87-4.12,15.37Z" />
        <polygon className="cls-1" points="399.83 164.95 403.66 146.9 474.98 146.9 484.11 105.3 413.01 105.3 416.44 88.24 489.42 88.24 498.4 47.06 377.63 47.06 342.56 206.83 463.31 206.83 472.8 164.95 399.83 164.95" />
        <path className="cls-1" d="M565.66,47.06l-95.12,160.27h57.88l10.24-19.44h56.94l2.58,19.44,51.89-.74-23.89-159.52h-60.53Zm-5.76,99.76l28.18-53.53,5,53.53h-33.18Z" />
      </svg>
    ),
    href: null,
    key: 'Breakit',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" id="e-commerce-success" viewBox="0 0 1074.71 254.95">
        <title>E-commerce success</title>
        <path className="cls-1" d="M513.74,176.75c-4.87,0-8.07,2.34-9.89,4.31-2.04,2.2-3.46,5.05-4.14,8.15h28.08c-.98-4.79-4.09-12.46-14.05-12.46Z" />
        <path className="cls-1" d="M513.92,159.16c-18.3,0-33.14,14.84-33.14,33.14s14.84,33.14,33.14,33.14,33.14-14.84,33.14-33.14-14.83-33.14-33.14-33.14Zm20.39,36.09c-.67,.68-1.58,1.06-2.53,1.06h-32.25c.98,5,4.82,9.3,10.16,11.21,5.36,1.92,10.76,.78,14.43-3.06,.24-.24,.47-.47,.69-.7,.66-.66,1.18-1.18,1.58-1.84,1.02-1.67,3.21-2.2,4.88-1.18,1.67,1.02,2.2,3.2,1.18,4.88-.84,1.38-1.78,2.32-2.61,3.15-.19,.2-.39,.4-.59,.6-3.89,4.06-8.98,6.19-14.39,6.19-2.48,0-5.03-.45-7.57-1.36-8.5-3.05-14.41-10.46-15.05-18.88-.53-7.05,1.86-14.18,6.4-19.09,3.99-4.3,9.22-6.58,15.1-6.58,16.71,0,21.43,15.07,21.58,23.04,.02,.95-.34,1.88-1.01,2.56Z" />
        <path className="cls-1" d="M599.5,178.37c-3.92,0-7.33,1.46-10.14,4.33-2.8,2.87-4.22,6.35-4.22,10.37s1.41,7.55,4.19,10.39c2.79,2.85,6.21,4.3,10.18,4.3s7.34-1.46,10.14-4.33c2.79-2.87,4.2-6.35,4.2-10.37s-1.42-7.5-4.22-10.37c-2.8-2.87-6.21-4.33-10.14-4.33Zm6.07,21.23c-1.65,1.82-3.64,2.71-6.07,2.71s-4.42-.88-6.07-2.71c-1.67-1.84-2.48-3.99-2.48-6.55s.81-4.71,2.48-6.54c1.65-1.81,3.64-2.69,6.07-2.69s4.42,.88,6.07,2.69c1.67,1.83,2.48,3.97,2.48,6.54s-.81,4.71-2.48,6.55Z" />
        <path className="cls-1" d="M652.04,178.15c-3.95,0-6.99,1.5-9.05,4.47-2.18-2.96-5.68-4.47-10.42-4.47-2.62,.02-4.9,.74-6.8,2.17v-1.18h-6.15v27.98h6.15v-16.56c.04-2.1,.69-3.75,2-5.05,1.3-1.3,2.88-1.93,4.79-1.93,2.11,.04,3.68,.64,4.82,1.86,1.15,1.23,1.71,2.83,1.71,4.89v16.8h6.12v-16.56c.04-2.1,.7-3.75,2.01-5.05,1.32-1.3,2.89-1.93,4.8-1.93,2.11,.04,3.68,.64,4.82,1.86,1.15,1.22,1.71,2.82,1.71,4.89v16.8h6.12v-17.18c-.09-3.66-1.25-6.57-3.44-8.66-2.19-2.07-5.29-3.13-9.2-3.13Z" />
        <path className="cls-1" d="M703.76,178.15c-3.95,0-6.99,1.5-9.05,4.47-2.18-2.96-5.68-4.47-10.42-4.47-2.62,.02-4.9,.74-6.79,2.17v-1.18h-6.15v27.98h6.15v-16.56c.04-2.1,.69-3.75,2-5.05,1.3-1.3,2.88-1.93,4.79-1.93,2.11,.04,3.68,.64,4.83,1.86,1.15,1.23,1.71,2.83,1.71,4.89v16.8h6.12v-16.56c.04-2.1,.7-3.75,2.01-5.05,1.32-1.3,2.89-1.93,4.8-1.93,2.11,.04,3.68,.64,4.82,1.86,1.15,1.22,1.71,2.82,1.71,4.89v16.8h6.12v-17.18c-.09-3.66-1.25-6.57-3.44-8.66-2.19-2.07-5.29-3.13-9.2-3.13Z" />
        <path className="cls-1" d="M767.3,179.31c-2.9,0-5.25,.91-7,2.7v-2.87h-5.73v27.98h5.73v-15.4c.03-2.09,.67-3.68,1.96-4.88,1.29-1.21,2.94-1.79,5.04-1.79,.49,0,.98,.02,1.5,.07l.77,.08v-5.74l-.63-.06c-.54-.05-1.08-.08-1.63-.08Z" />
        <path className="cls-1" d="M785.93,183.82c3.55,0,5.9,1.69,7.18,5.16v.02h5.77l-.14-.46c-.92-3.02-2.5-5.49-4.7-7.34-2.23-1.87-4.97-2.82-8.16-2.82-3.71,0-6.93,1.46-9.58,4.34-2.63,2.87-3.96,6.35-3.96,10.35s1.33,7.53,3.97,10.38c2.64,2.86,5.87,4.31,9.58,4.31,2.81,0,5.29-.75,7.38-2.24,2.06-1.47,3.67-3.5,4.79-6.02l.43-.98h-5.81l-.2,.35c-1.34,2.32-3.49,3.44-6.59,3.44-2.26,0-4.1-.83-5.6-2.54-1.51-1.71-2.27-3.97-2.27-6.7s.76-4.94,2.27-6.68c1.5-1.73,3.33-2.57,5.65-2.57Z" />
        <path className="cls-1" d="M568.43,183.82c3.63,0,6.03,1.69,7.33,5.16v.02h5.89l-.14-.46c-.94-3.02-2.55-5.49-4.8-7.34-2.27-1.87-5.08-2.82-8.34-2.82-3.79,0-7.08,1.46-9.78,4.34-2.69,2.87-4.05,6.35-4.05,10.35s1.36,7.53,4.05,10.38c2.7,2.86,5.99,4.31,9.78,4.31,2.87,0,5.41-.75,7.53-2.24,2.11-1.47,3.75-3.5,4.89-6.02l.44-.98h-5.93l-.21,.35c-1.37,2.32-3.57,3.44-6.73,3.44-2.31,0-4.18-.83-5.72-2.54-1.54-1.71-2.32-3.97-2.32-6.7s.78-4.94,2.32-6.68c1.54-1.73,3.41-2.57,5.77-2.57Z" />
        <path className="cls-1" d="M816.47,178.37c-3.72,0-6.95,1.46-9.59,4.34-2.63,2.87-3.96,6.35-3.96,10.35s1.33,7.53,3.97,10.38c2.64,2.86,5.87,4.31,9.58,4.31,3.07,0,5.74-.89,7.92-2.64,2.17-1.73,3.77-4.05,4.76-6.88l.03-.09h-5.86c-1.44,2.79-3.68,4.16-6.86,4.16-2.27,0-4.05-.71-5.42-2.17-1.24-1.31-2-3.05-2.27-5.17h21.14l.09-1.91c0-4-1.33-7.48-3.95-10.35-2.64-2.88-5.86-4.34-9.58-4.34Zm-7.35,11.18c.48-1.64,1.29-2.99,2.4-3.99,1.29-1.17,2.91-1.74,4.94-1.74s3.66,.57,4.97,1.75c1.13,1.01,1.94,2.35,2.42,3.99h-14.73Z" />
        <path className="cls-1" d="M735.5,178.37c-3.72,0-6.95,1.46-9.59,4.34-2.63,2.87-3.96,6.35-3.96,10.35s1.33,7.53,3.97,10.38c2.64,2.86,5.87,4.31,9.58,4.31,3.07,0,5.74-.89,7.92-2.64,2.17-1.73,3.77-4.05,4.76-6.88l.03-.09h-5.86c-1.44,2.79-3.68,4.16-6.86,4.16-2.27,0-4.05-.71-5.42-2.17-1.24-1.31-2-3.05-2.27-5.17h21.14l.09-1.91c0-4-1.33-7.48-3.95-10.35-2.64-2.88-5.86-4.34-9.58-4.34Zm-7.35,11.18c.48-1.64,1.29-2.99,2.4-3.99,1.29-1.17,2.91-1.74,4.94-1.74s3.66,.57,4.97,1.75c1.13,1.01,1.94,2.35,2.42,3.99h-14.73Z" />
        <polygon className="cls-1" points="75.78 54.32 78.36 32.2 39.31 32.2 25.89 135.96 66.36 135.96 69.42 115.02 47.66 115.02 50.24 93.26 68.95 93.26 71.66 73.14 52.63 73.14 54.84 54.55 75.78 54.32" />
        <polygon className="cls-1" points="928.89 135.96 931.95 115.02 910.19 115.02 912.77 93.26 931.48 93.26 934.19 73.14 915.16 73.14 917.36 54.55 938.3 54.32 940.89 32.2 901.83 32.2 888.42 135.96 928.89 135.96" />
        <polygon className="cls-1" points="599.61 115.02 602.19 93.26 620.9 93.26 623.61 73.14 604.58 73.14 606.79 54.55 627.73 54.32 630.31 32.2 591.26 32.2 577.84 135.96 618.31 135.96 621.37 115.02 599.61 115.02" />
        <polygon className="cls-1" points="450.08 115.02 428.31 115.02 430.9 93.26 449.61 93.26 452.31 73.14 433.28 73.14 435.49 54.55 456.43 54.32 459.02 32.2 419.96 32.2 406.55 135.96 447.02 135.96 450.08 115.02" />
        <polygon className="cls-1" points="76.95 105.02 99.72 105.02 102.54 86.67 79.25 86.67 76.95 105.02" />
        <path className="cls-1" d="M136.95,115.26c-6.88-1.24-9.71-16.06-6.71-33s12.18-40.94,26.12-21.35c7.24-9.71,13.06-17.47,13.06-17.47,0,0-5.82-13.62-21.53-13.76-17.06-.16-36.21,20.87-38.47,58.41-2.24,37.18,9.35,51.18,26.47,50.82,8.29-.71,18.18-5.29,22.59-12.71-7.76-16.76-9.53-19.06-9.53-19.06,0,0-5.12,9.35-12,8.12Z" />
        <path className="cls-1" d="M825.25,126.2c-7.76-16.76-9.53-19.06-9.53-19.06,0,0-5.12,9.35-12,8.12-6.88-1.24-9.71-16.06-6.71-33s12.18-40.94,26.12-21.35c7.24-9.71,13.06-17.47,13.06-17.47,0,0-5.82-13.62-21.53-13.76-17.06-.16-36.21,20.87-38.47,58.41-2.24,37.18,9.35,51.18,26.47,50.82,8.29-.71,18.18-5.29,22.59-12.71Z" />
        <path className="cls-1" d="M882.43,126.2c-7.76-16.76-9.53-19.06-9.53-19.06,0,0-5.12,9.35-12,8.12-6.88-1.24-9.71-16.06-6.71-33s12.18-40.94,26.12-21.35c7.24-9.71,13.06-17.47,13.06-17.47,0,0-5.82-13.62-21.53-13.76-17.06-.16-36.21,20.87-38.47,58.41-2.24,37.18,9.35,51.18,26.47,50.82,8.29-.71,18.18-5.29,22.59-12.71Z" />
        <path className="cls-1" d="M571.74,126.2c-7.76-16.76-9.53-19.06-9.53-19.06,0,0-5.12,9.35-12,8.12-6.88-1.24-9.71-16.06-6.71-33s12.18-40.94,26.12-21.35c7.24-9.71,13.06-17.47,13.06-17.47,0,0-5.82-13.62-21.53-13.76-17.06-.16-36.21,20.87-38.47,58.41-2.24,37.18,9.35,51.18,26.47,50.82,8.29-.71,18.18-5.29,22.59-12.71Z" />
        <path className="cls-1" d="M207.65,29.81c-5.57-.98-16.49-.36-26.98,14.73-6.23,8.97-10.96,21.29-13.31,34.7s-2.1,26.61,.71,37.16c4.73,17.76,14.78,22.05,20.35,23.03,.99,.17,2.16,.3,3.46,.3,5.99,0,14.9-2.63,23.52-15.03,6.23-8.97,10.96-21.29,13.31-34.7,5.57-31.76-3.29-57.07-21.07-60.19Zm1.37,56.74c-1.8,10.24-5.37,19.86-9.79,26.39-.04,.06-.08,.12-.12,.18-3.17,4.61-10.35,3.35-11.76-2.06-.02-.07-.04-.14-.05-.21-1.94-7.65-2.03-17.9-.23-28.14,1.8-10.24,5.37-19.86,9.8-26.39,.1-.15,.2-.29,.3-.43,2.89-4.14,8.97-2.74,10.34,2.12,.1,.37,.21,.75,.31,1.14,1.94,7.65,3.01,17.17,1.22,27.41Z" />
        <polygon className="cls-1" points="274.25 91.96 273.42 91.96 270.01 32.2 246.48 32.2 232.95 135.96 250.36 135.96 256.72 84.08 257.89 84.08 260.72 135.96 274.6 135.96 292.48 83.2 293.31 83.2 285.98 135.96 305.31 135.96 319.07 32.2 293.42 32.2 274.25 91.96" />
        <polygon className="cls-1" points="380.9 32.2 361.72 91.96 360.9 91.96 357.49 32.2 333.96 32.2 320.43 135.96 337.84 135.96 344.19 84.08 345.37 84.08 348.19 135.96 362.08 135.96 379.96 83.2 380.78 83.2 373.46 135.96 392.78 135.96 406.55 32.2 380.9 32.2" />
        <path className="cls-1" d="M485.66,95.97l6.35,40h20.24s-5.65-31.53-8-44.94c3.76-1.42,12-8,14.11-26.12,2.59-27.06-14.35-32.71-18.35-32.71h-27.76l-14.59,103.77h19.06l5.17-40h3.77Zm2.48-42.12s12.11-4,11.17,11.06c-.95,15.06-14.36,13.41-14.36,13.41l3.19-24.47Z" />
        <path className="cls-1" d="M662.94,110.99c-1.76-2.12-2.75-2.79-2.75-2.79l-11.47,18.36s7.94,15.01,28.76,12.36c20.82-2.65,24.53-31.77,23.12-40.24-1.41-8.47-5.12-18.18-15.35-24.88s-5.29-16.59-2.82-19.41c2.47-2.82,8.29-4.59,15.71,3.71,3.53-4.76,11.29-17.29,11.29-17.29,0,0-6.53-11.12-23.65-11.12s-27.53,21.88-26.47,36.88,7.46,21.89,13.94,26.29c7.35,5,9.76,13.35,5.65,19.41-4.37,6.44-11.02,4.2-15.96-1.27Z" />
        <path className="cls-1" d="M959.69,92.85c7.35,5,9.76,13.35,5.65,19.41-4.37,6.44-11.02,4.2-15.96-1.27-1.76-2.12-2.75-2.79-2.75-2.79l-11.47,18.36s7.94,15.01,28.76,12.36c20.82-2.65,24.53-31.77,23.12-40.24-1.41-8.47-5.12-18.18-15.35-24.88s-5.29-16.59-2.82-19.41c2.47-2.82,8.29-4.59,15.71,3.71,3.53-4.76,11.29-17.29,11.29-17.29,0,0-6.53-11.12-23.65-11.12s-27.53,21.88-26.47,36.88,7.46,21.89,13.94,26.29Z" />
        <path className="cls-1" d="M1024.64,73.79c-10.24-6.71-5.29-16.59-2.82-19.41,2.47-2.82,8.29-4.59,15.71,3.71,3.53-4.76,11.29-17.29,11.29-17.29,0,0-6.53-11.12-23.65-11.12s-27.53,21.88-26.47,36.88,7.46,21.89,13.94,26.29c7.35,5,9.76,13.35,5.65,19.41-4.37,6.44-11.02,4.2-15.96-1.27-1.76-2.12-2.75-2.79-2.75-2.79l-11.47,18.36s7.94,15.01,28.76,12.36c20.82-2.65,24.53-31.77,23.12-40.24-1.41-8.47-5.12-18.18-15.35-24.88Z" />
        <path className="cls-1" d="M774.19,32.55h-19.59s-8.29,64.71-9.35,71.06c-1.06,6.35-5.12,12.53-9.71,12.53s-6.53-6.71-5.12-17.29c1.41-10.59,9.18-66.65,9.18-66.65l-19.94,.35s-6.18,45.47-9,70c-2.82,24.53,7.06,35.58,23.47,36.67,16.41,1.09,27-11.26,31.24-39.67s8.82-67,8.82-67Z" />
        <polygon className="cls-1" points="452.02 202.68 448.86 185.93 444.66 185.93 448.67 207.27 442.42 218.05 447.1 218.05 466.07 185.93 461.39 185.93 452.02 202.68" />
        <path className="cls-1" d="M431.05,185.43c-2.59,.16-5.02,1.65-6.94,3.8l2.17-11.06h-4.74l-5.81,29.74h4.72l.74-3.77c4.56,6.74,14.3,3.63,17.36-2.93,3.74-6.39,1.15-16.17-7.49-15.77Zm3.94,13.92c-1.61,3.16-5.56,5.48-8.91,4-5.91-2.58-2.65-11.97,2.43-13.75,6.56-2.27,9.4,4.52,6.49,9.75Z" />
        <polygon className="cls-1" points="996 183.76 1003.91 183.76 1003.91 207.11 1011.45 207.11 1011.45 183.76 1019.23 183.76 1019.23 177.41 996 177.41 996 183.76" />
        <rect className="cls-1" x="985.41" y="177.41" width="7.09" height="29.7" />
        <path className="cls-1" d="M869.24,187.76c0-8.15-4.7-10.35-10.73-10.35h-11.95v29.56h6.93l.06-10.88,1.88-.09,5.27,10.97h9.6l-7.37-11.38c3.41-1.24,6.32-3.31,6.32-7.83Zm-10.43,3.29h-5.33v-7.12h5.28c1.34,0,2.69,.94,2.69,3.83,0,1.69-1.26,3.29-2.64,3.29Z" />
        <path className="cls-1" d="M951.12,187.76c0-8.15-4.7-10.35-10.73-10.35h-11.95v29.56h6.93l.06-10.88,1.88-.09,5.27,10.97h9.6l-7.37-11.38c3.41-1.24,6.32-3.31,6.32-7.83Zm-10.43,3.29h-5.33v-7.12h5.28c1.34,0,2.69,.94,2.69,3.83,0,1.69-1.26,3.29-2.64,3.29Z" />
        <polygon className="cls-1" points="873.31 206.98 892.48 206.98 892.48 200.96 881.46 200.96 881.46 195.08 890.42 195.08 890.42 189.06 881.46 189.06 881.46 183.98 892.48 183.98 892.48 177.41 873.31 177.41 873.31 206.98" />
        <path className="cls-1" d="M910.09,183.91c2.69,0,5.03,1.66,6.3,4.12h7.88c-1.15-6.95-7.05-11.19-14.02-11.19-8.5,0-15.05,5.69-15.05,15.25s6.55,15.26,15.05,15.26c6.91,0,12.78-4.18,13.99-11.04h-7.71c-1.24,2.6-3.66,4.36-6.44,4.36-4.05,0-7.34-3.75-7.34-8.38s3.29-8.38,7.34-8.38Z" />
        <path className="cls-1" d="M973.42,195.97c0,2.73-2.21,4.94-4.94,4.94s-4.94-2.21-4.94-4.94v-18.55h-8.12v19.08c0,6.87,5.67,10.98,12.51,10.98s12.55-4.11,12.55-10.98v-19.08h-7.06v18.55Z" />
      </svg>
    ),
    href: 'https://www.ecommercerecruit.se/e-commerce-success/',
    key: 'E-commerce success',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" id="handelstrender" viewBox="0 0 1074.71 276.98">
        <title>Handelstrender</title>
        <g>
          <path className="cls-1" d="M174.78,49.74c-14.49,0-26.11,10.46-26.11,24.94v13.7h16.87v-13.7c0-4.3,3.62-7.92,8.15-7.92s9.07,2.25,9.07,6.55v21.66c-16.07,11.55-40.68,36.84-40.68,62.87s9.24,44.44,57.64,45.87V74.68c0-14.49-10.68-24.94-24.94-24.94Zm7.98,136.35c-7.92,0-24.91-5.88-24.91-25.88,0-14.26,12.46-36,24.91-42.13v68.02Z" />
          <path className="cls-1" d="M215.57,203.72V48.65h9.06c33.73,0,49.12,23.54,49.12,54.56v100.51h-18.34V103.2c0-18.56-4.3-33.05-21.51-35.54V203.72h-18.34Z" />
          <path className="cls-1" d="M344.84,203.94h-9.28c-32.6,0-48.9-37.13-48.9-74.03s12.9-69.72,40.07-81.5V16.27h18.11V203.94Zm-18.11-135.38c-15.62,8.15-21.96,34.64-21.96,61.35s7.47,49.58,21.96,53.88V68.57Z" />
          <path className="cls-1" d="M393.96,48.65c41.43,0,36.22,69.5,36.22,69.5h-54.33v7.92c0,19.02,3.85,59.54,41.43,59.54v18.11h-.68c-53.88,0-59.08-56.59-59.08-77.65,.45-30.79,2.49-77.42,36.45-77.42Zm17.66,51.39c.23-11.09-3.17-33.28-17.66-33.28-12.45,0-16.98,24.9-16.75,33.28h34.41Z" />
          <path className="cls-1" d="M461.88,16.05V203.72h-18.34V16.05h18.34Z" />
          <path className="cls-1" d="M516.66,151.65c0,24.9-8.83,40.75-19.24,52.07h-27.17c16.07-12.9,28.07-22.86,28.07-52.07,0-14.49-4.3-24.22-9.06-34.86-4.98-10.19-11.09-21.51-11.09-36.9,0-12.68,3.62-23.09,8.15-31.24h22.41c-6.56,6.34-12.22,16.75-12.22,31.24,0,10.19,3.85,18.34,9.06,29.2,5.21,10.87,11.09,24.22,11.09,42.56Z" />
          <path className="cls-1" d="M575.97,203.72V126.07c0-38.48,14.71-59.31,38.71-79.01v25.35c-10.87,9.06-20.6,26.03-20.6,53.65v77.65h-18.11Z" />
          <path className="cls-1" d="M659.06,48.65c41.43,0,36.22,69.5,36.22,69.5h-54.33v7.92c0,19.02,3.85,59.54,41.43,59.54v18.11h-.68c-53.88,0-59.08-56.59-59.08-77.65,.45-30.79,2.49-77.42,36.45-77.42Zm17.66,51.39c.23-11.09-3.17-33.28-17.66-33.28-12.45,0-16.98,24.9-16.75,33.28h34.41Z" />
          <path className="cls-1" d="M708.19,203.72V48.65h9.06c33.73,0,49.12,23.54,49.12,54.56v100.51h-18.34V103.2c0-18.56-4.3-33.05-21.51-35.54V203.72h-18.34Z" />
          <path className="cls-1" d="M837.45,203.94h-9.28c-32.6,0-48.9-37.13-48.9-74.03s12.9-69.72,40.07-81.5V16.27h18.11V203.94Zm-18.11-135.38c-15.62,8.15-21.96,34.64-21.96,61.35s7.47,49.58,21.96,53.88V68.57Z" />
          <path className="cls-1" d="M886.57,48.65c41.43,0,36.22,69.5,36.22,69.5h-54.33v7.92c0,19.02,3.85,59.54,41.43,59.54v18.11h-.68c-53.88,0-59.08-56.59-59.08-77.65,.45-30.79,2.49-77.42,36.45-77.42Zm17.66,51.39c.23-11.09-3.17-33.28-17.66-33.28-12.45,0-16.98,24.9-16.75,33.28h34.41Z" />
          <path className="cls-1" d="M935.7,203.72V126.07c0-38.48,14.71-59.31,38.71-79.01v25.35c-10.87,9.06-20.6,26.03-20.6,53.65v77.65h-18.11Z" />
          <path className="cls-1" d="M995.72,181.24c6.02,0,11.19,4.95,11.19,11.4,0,6.02-5.16,11.19-11.19,11.19s-11.4-5.16-11.4-11.19c0-6.45,5.16-11.4,11.4-11.4Z" />
          <path className="cls-1" d="M553.54,49.35v-16.75h-18.11v16.75h-14.04v18.11h14.04V204.41h18.11V67.46h13.81v-18.11h-13.81Z" />
          <path className="cls-1" d="M113.46,63.47c-6.87-6.76-16.18-10.86-26.68-11.86V16.27h-16.94V203.72h16.94V68.2c6.07,.84,11.23,3.22,15.11,7.04,5.35,5.26,8.17,13.28,8.17,23.2v105.28h16.5V98.43c0-14.45-4.53-26.54-13.1-34.97Z" />
        </g>
        <g>
          <path className="cls-1" d="M888.17,236.24h-8.65v24.23h-3.59v-24.23h-8.65v-3.26h20.9v3.26Z" />
          <path className="cls-1" d="M451.8,236.24h-12.04v8.4h11.13v3.26h-11.13v9.3h12.62v3.26h-15.83v-27.49h15.25v3.26Z" />
          <path className="cls-1" d="M858.81,236.24h-12.04v8.4h11.13v3.26h-11.13v9.3h12.62v3.26h-15.83v-27.49h15.25v3.26Z" />
          <path className="cls-1" d="M328.5,257.2h10.87v3.26h-13.79v-27.49h2.92v24.23Z" />
          <path className="cls-1" d="M942.41,257.2h10.87v3.26h-13.79v-27.49h2.92v24.23Z" />
          <path className="cls-1" d="M90.93,246.57c0,2.98-.51,5.5-1.53,7.58-1.02,2.08-2.49,3.65-4.41,4.72-1.92,1.07-4.23,1.6-6.93,1.6h-10.25v-27.49h10.59c2.62,0,4.87,.52,6.74,1.56,1.87,1.04,3.3,2.57,4.31,4.6,1,2.03,1.5,4.51,1.5,7.44Zm-3.97-.08c0-3.55-.77-6.15-2.3-7.79-1.53-1.64-3.79-2.47-6.77-2.47h-6.26v20.97h6.12c3.11,0,5.42-.87,6.93-2.6,1.51-1.73,2.27-4.44,2.27-8.11Z" />
          <path className="cls-1" d="M104.21,260.47h-3.85v-27.49h3.85v27.49Z" />
          <path className="cls-1" d="M133.42,254.43h.08v-21.45h3.63v27.49h-3.93l-14.56-21.47h-.08v21.47h-3.63v-27.49h3.95l14.54,21.45Z" />
          <path className="cls-1" d="M162.09,246.76c0-2.83,.6-5.32,1.8-7.48,1.2-2.16,2.85-3.83,4.97-5.01,2.11-1.18,4.49-1.76,7.12-1.76s5,.58,7.09,1.74,3.74,2.82,4.94,4.96,1.8,4.65,1.8,7.51-.6,5.33-1.8,7.48c-1.2,2.15-2.84,3.81-4.94,4.98-2.09,1.17-4.46,1.75-7.09,1.75s-5.02-.59-7.13-1.78c-2.11-1.19-3.76-2.86-4.96-5.01-1.2-2.15-1.8-4.61-1.8-7.39Zm13.85-11.14c-1.94,0-3.65,.44-5.15,1.33-1.5,.89-2.66,2.18-3.49,3.87-.83,1.69-1.24,3.7-1.24,6.01s.42,4.22,1.26,5.88c.84,1.66,2.01,2.92,3.51,3.8,1.49,.88,3.2,1.31,5.11,1.31s3.63-.44,5.13-1.31c1.49-.88,2.66-2.14,3.51-3.81,.84-1.66,1.26-3.63,1.26-5.91s-.42-4.27-1.24-5.95c-.83-1.68-1.99-2.97-3.49-3.87-1.49-.9-3.22-1.35-5.17-1.35Z" />
          <path className="cls-1" d="M213.14,251.55h.08c.08-.23,8.3-18.58,8.3-18.58h5.25v27.49h-3.63v-23.01h-.08l-8.33,18.43h-3.44l-8.3-18.8h-.12v23.39h-3.63v-27.49h5.87s7.96,18.28,8.04,18.58Z" />
          <path className="cls-1" d="M775.2,251.55h.08c.08-.23,8.3-18.58,8.3-18.58h5.25v27.49h-3.63v-23.01h-.08l-8.33,18.43h-3.44l-8.3-18.8h-.12v23.39h-3.63v-27.49h5.87s7.96,18.28,8.04,18.58Z" />
          <path className="cls-1" d="M246.23,252.63c.22,.63,.45,1.34,.69,2.16,.24,.81,.43,1.59,.57,2.34h.08c.36-1.54,.76-2.93,1.22-4.18l7.53-19.97h4.05l-10.92,27.49h-3.93l-10.8-27.49h4.21l7.31,19.65Z" />
          <path className="cls-1" d="M289.86,260.47h-4.27l-3.4-8.36h-12.07l-3.24,8.36h-4.01l11.08-27.49h4.13l11.77,27.49Zm-15.6-29.87h-3.87v-4.03h3.87v4.03Zm-3.04,18.62h9.82s-4.65-11.54-5-12.76h-.08c-.38,1.71-4.74,12.76-4.74,12.76Zm10.33-18.62h-3.85v-4.03h3.85v4.03Z" />
          <path className="cls-1" d="M514.16,260.47h-4.27l-3.4-8.36h-12.07l-3.24,8.36h-4.01l11.08-27.49h4.13l11.77,27.49Zm-18.65-11.25h9.82s-4.65-11.54-5-12.76h-.08c-.38,1.71-4.74,12.76-4.74,12.76Z" />
          <path className="cls-1" d="M917.73,260.47h-4.27l-3.4-8.36h-12.07l-3.24,8.36h-4.01l11.08-27.49h4.13l11.77,27.49Zm-18.65-11.25h9.82s-4.65-11.54-5-12.76h-.08c-.38,1.71-4.74,12.76-4.74,12.76Z" />
          <path className="cls-1" d="M313.41,240.61c0,1.89-.42,3.45-1.26,4.69-.84,1.24-2.07,2.12-3.69,2.64l-.44,.1c.36,.16,8.88,12.42,8.88,12.42h-3.33s-6.09-8.48-6.6-9.3c-.51-.82-1.07-1.59-1.74-1.99-.67-.39-1.21-.63-2.22-.63h-2.59v11.91h-2.97v-27.49h8.29c2.57,0,4.49,.65,5.76,1.96,1.28,1.31,1.92,3.2,1.92,5.67Zm-3.08,.11c0-1.52-.39-2.65-1.17-3.38-.78-.73-1.94-1.1-3.5-1.1h-5.26v9.06h5.17c1.54,0,2.72-.38,3.53-1.13s1.22-1.9,1.22-3.44Z" />
          <path className="cls-1" d="M832.18,240.61c0,1.89-.42,3.45-1.26,4.69-.84,1.24-2.07,2.12-3.69,2.64l-.44,.1c.36,.16,8.88,12.42,8.88,12.42h-3.33s-6.09-8.48-6.6-9.3c-.51-.82-1.07-1.59-1.74-1.99-.67-.39-1.21-.63-2.22-.63h-2.59v11.91h-2.97v-27.49h8.29c2.57,0,4.49,.65,5.76,1.96,1.28,1.31,1.92,3.2,1.92,5.67Zm-3.08,.11c0-1.52-.39-2.65-1.17-3.38-.78-.73-1.94-1.1-3.5-1.1h-5.26v9.06h5.17c1.54,0,2.72-.38,3.53-1.13s1.22-1.9,1.22-3.44Z" />
          <path className="cls-1" d="M425.11,239.99c0,1.28-.3,2.43-.91,3.46-.61,1.03-1.45,1.83-2.54,2.39,1.49,.43,2.64,1.21,3.45,2.36,.8,1.15,1.21,2.58,1.21,4.28s-.35,3.11-1.04,4.3c-.7,1.19-1.7,2.11-3.01,2.74-1.31,.63-2.88,.95-4.69,.95h-9.72v-27.49h9.19c1.79,0,3.29,.29,4.49,.88,1.21,.59,2.1,1.41,2.69,2.46,.59,1.05,.88,2.28,.88,3.68Zm-3.31,.09c0-1.23-.43-2.17-1.29-2.84-.86-.67-2.26-1-4.2-1h-5.16v8.25h5.57c1.7,0,2.97-.34,3.81-1.01,.84-.68,1.27-1.81,1.27-3.39Zm1.12,12.4c0-1.65-.51-2.85-1.54-3.6s-2.56-1.12-4.61-1.12h-5.62v9.45h6.05c1.98,0,3.43-.39,4.35-1.17,.92-.78,1.38-1.97,1.38-3.55Z" />
          <path className="cls-1" d="M470.9,252.63c.22,.63,.45,1.34,.69,2.16,.24,.81,.43,1.59,.57,2.34h.08c.36-1.54,.76-2.93,1.22-4.18l7.53-19.97h4.05l-10.92,27.49h-3.93l-10.8-27.49h4.21l7.31,19.65Z" />
          <path className="cls-1" d="M569.55,254.43h.08v-21.45h3.63v27.49h-3.93l-14.56-21.47h-.08v21.47h-3.63v-27.49h3.95l14.54,21.45Z" />
          <path className="cls-1" d="M587.85,260.47h-3.85v-27.49h3.85v27.49Z" />
          <path className="cls-1" d="M617.06,254.43h.08v-21.45h3.63v27.49h-3.93l-14.56-21.47h-.08v21.47h-3.63v-27.49h3.95l14.54,21.45Z" />
          <path className="cls-1" d="M643.6,235.62c-1.97,0-3.67,.46-5.09,1.37-1.42,.91-2.49,2.2-3.23,3.86-.74,1.66-1.11,3.59-1.11,5.79,0,2.34,.39,4.34,1.17,6.01,.78,1.67,1.92,2.95,3.42,3.83s3.32,1.33,5.45,1.33c1.37,0,2.78-.24,4.24-.71,1.46-.47,2.64-1.06,3.57-1.74v-5.65h-8.04v-3.28h11.75v10.48c-1.51,1.25-3.28,2.23-5.3,2.94s-4.08,1.07-6.17,1.07c-2.95,0-5.48-.57-7.58-1.7-2.11-1.13-3.71-2.77-4.81-4.91s-1.65-4.72-1.65-7.72c0-2.81,.56-5.28,1.69-7.4,1.12-2.12,2.71-3.77,4.76-4.94s4.38-1.76,7-1.76c3.03,0,5.53,.69,7.52,2.06,1.98,1.38,3.35,3.36,4.12,5.96l-3.75,.84c-.55-1.88-1.51-3.3-2.87-4.28-1.36-.98-3.05-1.47-5.07-1.47Z" />
          <path className="cls-1" d="M682.85,260.47h-3.85v-27.49h3.85v27.49Z" />
          <path className="cls-1" d="M712.07,254.43h.08v-21.45h3.63v27.49h-3.93l-14.56-21.47h-.08v21.47h-3.63v-27.49h3.95l14.54,21.45Z" />
          <path className="cls-1" d="M725.21,246.76c0-2.83,.6-5.32,1.8-7.48,1.2-2.16,2.85-3.83,4.97-5.01,2.11-1.18,4.49-1.76,7.12-1.76s5,.58,7.09,1.74,3.74,2.82,4.94,4.96,1.8,4.65,1.8,7.51-.6,5.33-1.8,7.48c-1.2,2.15-2.84,3.81-4.94,4.98-2.09,1.17-4.46,1.75-7.09,1.75s-5.02-.59-7.13-1.78c-2.11-1.19-3.76-2.86-4.96-5.01-1.2-2.15-1.8-4.61-1.8-7.39Zm13.85-11.14c-1.94,0-3.65,.44-5.15,1.33-1.5,.89-2.66,2.18-3.49,3.87-.83,1.69-1.24,3.7-1.24,6.01s.42,4.22,1.26,5.88c.84,1.66,2.01,2.92,3.51,3.8,1.49,.88,3.2,1.31,5.11,1.31s3.63-.44,5.12-1.31c1.49-.88,2.66-2.14,3.51-3.81s1.26-3.63,1.26-5.91-.42-4.27-1.24-5.95c-.83-1.68-1.99-2.97-3.49-3.87-1.5-.9-3.22-1.35-5.17-1.35Z" />
          <path className="cls-1" d="M928.4,260.47h-3.85v-27.49h3.85v27.49Z" />
          <path className="cls-1" d="M371.49,246.57c0,2.98-.49,5.5-1.47,7.58-.98,2.08-2.4,3.65-4.24,4.72-1.85,1.07-4.07,1.6-6.67,1.6h-9.86v-27.49h10.18c2.52,0,4.68,.52,6.48,1.56,1.8,1.04,3.18,2.57,4.14,4.6,.96,2.03,1.44,4.51,1.44,7.44Zm-3.82-.08c0-3.55-.74-6.15-2.21-7.79-1.48-1.64-3.64-2.47-6.5-2.47h-6.02v20.97h5.89c2.99,0,5.21-.87,6.67-2.6,1.46-1.73,2.18-4.44,2.18-8.11Z" />
          <path className="cls-1" d="M396.92,248.85c-.54-1.03-1.4-1.9-2.58-2.6-1.18-.7-2.78-1.32-4.79-1.86-1.46-.39-2.58-.76-3.37-1.11-.78-.35-1.36-.79-1.74-1.31-.38-.52-.57-1.23-.57-2.1s.21-1.6,.63-2.24c.42-.64,1-1.14,1.75-1.49,.75-.35,1.61-.52,2.58-.52,1.48,0,2.66,.43,3.52,1.29,.36,.36,.67,.85,.94,1.41,.11-.01,.21-.03,.32-.03h3.04c-.23-.78-.53-1.49-.92-2.13-.72-1.19-1.67-2.09-2.86-2.72-1.19-.62-2.55-.94-4.09-.94-1.46,0-2.78,.32-3.96,.95-1.18,.63-2.11,1.5-2.78,2.62-.67,1.11-1,2.36-1,3.73,0,1.26,.17,2.35,.51,3.27,.34,.92,.95,1.74,1.83,2.47s2.09,1.34,3.66,1.86c.35,.12,.95,.31,1.81,.54,1.44,.41,2.54,.78,3.3,1.11,.91,.39,1.59,.9,2.06,1.55,.46,.64,.69,1.54,.69,2.69,0,.86-.24,1.64-.73,2.33-.49,.69-1.14,1.23-1.97,1.62-.83,.39-1.73,.58-2.71,.58-1.88,0-3.36-.57-4.42-1.7-.51-.54-.92-1.22-1.23-2.02h-3.19c.5,1.89,1.34,3.39,2.52,4.52,1.61,1.54,3.76,2.31,6.45,2.31,1.55,0,2.95-.36,4.19-1.08s2.21-1.7,2.91-2.95c.7-1.25,1.05-2.65,1.05-4.2s-.27-2.82-.81-3.85Z" />
          <path className="cls-1" d="M542.6,260.46h-4.03l-11.29-12.56h-3.26v12.56h-3v-27.49h3v11.93h3.24c2.62-3.01,7.48-8.59,10.39-11.93h3.97c-1.17,1.36-4.13,4.77-11.67,13.42l12.65,14.07Z" />
        </g>
      </svg>
    ),
    href: 'https://www.handelstrender.se/vi-maste-ga-samman-digitalt/?pin-request=ht-6367e9c3c0e30',
    key: 'Handelstrender',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" id="butikstrender" viewBox="0 0 1074.71 254.95">
        <title>Butikstrender</title>
        <path className="cls-1" d="M94.28,124.44v-.31c12.2-2.47,17.29-12.96,17.29-24.7,0-16.51-9.57-27.47-30.1-27.47H38.1v82.72h28.71v-20.05h8.02c5.25,0,11.58,4.78,11.58,13.27,0,8.95-6.33,15.07-11.58,15.07H35.21l-9.82,19.2h56.09c23.93,0,33.19-13.43,33.19-32.57,0-11.42-5.87-23.77-20.38-25.16Zm-20.53-9.26h-6.94v-22.54h6.94c5.87,0,9.11,5.25,9.11,11.42s-3.24,11.12-9.11,11.12Z" />
        <path className="cls-1" d="M503.31,96.35h-25.93l7.94-24.39h68.3v24.39h-21.61v85.82h-28.71V96.35Z" />
        <path className="cls-1" d="M152.18,71.96v79.49c0,10.65,3.24,13.43,8.64,13.43s8.64-2.78,8.64-13.43V71.96h28.71v72.24c0,30.87-13.27,40.13-37.35,40.13s-37.35-9.26-37.35-40.13V71.96h28.71Z" />
        <path className="cls-1" d="M282.53,71.96h28.71v110.21h-28.71V71.96Z" />
        <path className="cls-1" d="M320.12,71.96h28.71v42.29h.31l21.3-42.29h30.56l-27.78,48.16,31.18,62.05h-32.72l-16.82-38.43-6.02,9.26v29.17h-28.71V71.96Z" />
        <path className="cls-1" d="M447.36,104v-2.64c0-6.53-2.64-11.97-8.24-11.97-6.22,0-9.02,4.82-9.02,9.79,0,21.92,47.29,11.19,47.29,51.77,0,23.63-13.84,34.2-39.36,34.2-23.96,0-37.33-8.24-37.33-31.25v-3.89h28v2.64c0,9.48,3.89,12.9,9.49,12.9,5.91,0,9.33-4.66,9.33-10.73,0-21.92-45.42-11.04-45.42-50.37,0-22.39,11.98-34.67,36.4-34.67s35.93,10.42,35.93,34.2h-27.07Z" />
        <path className="cls-1" d="M722.83,71.96h32.11l18.52,67.92h.31V71.96h26.86v110.21h-31.49l-19.14-68.07h-.31v68.07h-26.86V71.96Z" />
        <path className="cls-1" d="M807.5,71.96h41.68c33.19,0,34.88,26.24,34.88,55.1s-1.7,55.1-34.88,55.1h-41.68V71.96Zm28.71,89.53h5.71c11.11,0,12.5-4.63,12.5-34.42s-1.39-34.42-12.5-34.42h-5.71v68.84Z" />
        <path className="cls-1" d="M225.04,96.35h-21.61v-24.39h71.93v24.39h-21.61v85.82h-28.71V96.35Z" />
        <path className="cls-1" d="M561.99,71.96h44.92c21.92,0,30.25,12.35,30.25,29.33,0,14.66-5.71,24.23-18.83,26.24v.31c13.89,1.23,18.06,10.03,18.06,24.7v9.42c0,5.71,0,13.12,1.39,15.28,.77,1.23,1.39,2.47,3.24,3.4v1.54h-30.56c-2.78-5.87-2.78-16.36-2.78-20.99v-7.41c0-12.5-2.47-15.74-9.42-15.74h-7.56v44.14h-28.71V71.96Zm28.71,46.62h5.71c8.18,0,12.04-5.25,12.04-13.12,0-8.95-3.55-12.81-12.19-12.81h-5.56v25.93Z" />
        <path className="cls-1" d="M970.29,71.96h44.92c21.92,0,30.25,12.35,30.25,29.33,0,14.66-5.71,24.23-18.83,26.24v.31c13.89,1.23,18.06,10.03,18.06,24.7v9.42c0,5.71,0,13.12,1.39,15.28,.77,1.23,1.39,2.47,3.24,3.4v1.54h-30.56c-2.78-5.87-2.78-16.36-2.78-20.99v-7.41c0-12.5-2.47-15.74-9.42-15.74h-7.56v44.14h-28.71V71.96Zm28.71,46.62h5.71c8.18,0,12.04-5.25,12.04-13.12,0-8.95-3.55-12.81-12.19-12.81h-5.56v25.93Z" />
        <path className="cls-1" d="M647.3,71.96h65.29v23.46h-36.58v18.83h34.27v22.54h-34.27v22.72h37.97v22.66h-66.68V71.96Z" />
        <path className="cls-1" d="M894.12,71.96h65.29v23.46h-36.58v18.83h34.27v22.54h-34.27v22.72h37.97v22.66h-66.68V71.96Z" />
      </svg>
    ),
    href: null,
    key: 'Butikstrender',
  },
]

const Media = () => (
  <section className="media-section">
    <div className="container">
      <div className="media-section__first-sentence">
        Jiroy i media
      </div>
      <div className="media-section__parts">
        {parts.map(({ href, icon, key }) => (
          <div className="media-section__part" key={key}>
            <LinkWrapper href={href}>
              {icon}
            </LinkWrapper>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Media
