/* eslint-disable max-len */
const parts = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="convinience"
      >
        <title>bekvämlighet</title>
        <path className="cls-2" d="M25.9,64.84l16.26.81a5.09,5.09,0,0,1,4.59,3.48L54,89.69l8.87-2.76L57.27,62.65,56,56.85Z" />
        <path className="cls-3" d="M54,90.69A1,1,0,0,1,53,90L45.81,69.46a4.11,4.11,0,0,0-3.7-2.82l-16.26-.8a1,1,0,0,1-1-.9,1,1,0,0,1,.74-1.07l30.07-8a1,1,0,0,1,.77.11,1,1,0,0,1,.46.63l1.31,5.8,5.56,24.28a1,1,0,0,1-.67,1.18l-8.87,2.76A1,1,0,0,1,54,90.69ZM32.36,64.16l9.85.49a6.06,6.06,0,0,1,5.49,4.17l6.89,19.63,7.07-2.2L55.22,58.09Z" />
        <polygon className="cls-2" points="54.48 36.92 48.19 34.77 53.06 23.09 59.36 25.24 54.48 36.92" />
        <path className="cls-4" d="M54.48,37.92a1.13,1.13,0,0,1-.32-.05l-6.3-2.16a1,1,0,0,1-.58-.53,1,1,0,0,1,0-.8L52.14,22.7a1,1,0,0,1,1.25-.56l6.29,2.16a1,1,0,0,1,.59.53,1,1,0,0,1,0,.8L55.4,37.31A1,1,0,0,1,54.48,37.92Zm-5-3.75,4.4,1.5L58,25.84l-4.39-1.5Z" />
        <path className="cls-2" d="M10.92,36.17l6,22a9,9,0,0,0,11,6.33L48.57,59a7.56,7.56,0,0,1,7.79,2.51L70.14,75.63l7-6L58.44,49.56A5.52,5.52,0,0,0,53.1,47.8L38.84,50.93,26.29,29.26a7.6,7.6,0,0,0-8.88-3.44l-1,.32A8.25,8.25,0,0,0,10.92,36.17Z" />
        <path className="cls-3" d="M70.14,76.63a1,1,0,0,1-.72-.3L55.64,62.24A6.59,6.59,0,0,0,48.83,60L28.12,65.51a10,10,0,0,1-12.2-7l-6-22a9.24,9.24,0,0,1,6.13-11.24l1-.32a8.64,8.64,0,0,1,10,3.89l12.18,21,13.56-3a6.52,6.52,0,0,1,6.3,2.08L77.87,69a1,1,0,0,1,.27.73,1,1,0,0,1-.35.71l-7,6A1,1,0,0,1,70.14,76.63ZM50.51,57.78a8.58,8.58,0,0,1,6.62,3.12L70.2,74.26l5.5-4.71-18-19.31a4.51,4.51,0,0,0-4.39-1.46L39.05,51.91A1,1,0,0,1,38,51.43L25.42,29.76a6.54,6.54,0,0,0-7.71-3l-1,.33a7.23,7.23,0,0,0-4.81,8.81h0l6,22.05a8,8,0,0,0,9.75,5.62l20.72-5.52A8.6,8.6,0,0,1,50.51,57.78Z" />
        <path className="cls-2" d="M21,38.9c.12.26,7,6.41,11,9.88a5,5,0,0,0,5.75.66L53.3,41a4.07,4.07,0,0,0,1.45-5.83h0a4.07,4.07,0,0,0-5.28-1.34L38.54,39.56A4.55,4.55,0,0,1,33,38.49l-5.89-6.13" />
        <path className="cls-3" d="M35.26,51.05a6,6,0,0,1-4-1.52c-11-9.72-11.07-10-11.2-10.22a1,1,0,0,1,1.72-1c.39.42,3.41,3.18,10.8,9.73a4.08,4.08,0,0,0,4.61.53L52.82,40.1a3.07,3.07,0,0,0,1.09-4.39,3.05,3.05,0,0,0-4-1L39,40.44a5.51,5.51,0,0,1-6.8-1.3l-5.85-6.09a1,1,0,0,1,0-1.41,1,1,0,0,1,1.42,0l5.89,6.12a3.54,3.54,0,0,0,4.39.88L49,32.92a5,5,0,0,1,6.57,1.68,5.06,5.06,0,0,1-1.81,7.26L38.14,50.32A6,6,0,0,1,35.26,51.05Z" />
        <path className="cls-3" d="M42.81,72.76H24.56A15.88,15.88,0,0,1,9.1,60.27L2.85,31.15A1,1,0,0,1,3.62,30a1,1,0,0,1,1.19.77l6.24,29.12A13.89,13.89,0,0,0,24.56,70.76H42.81a1,1,0,0,1,0,2Z" />
        <path className="cls-3" d="M14.55,96a.92.92,0,0,1-.4-.08,1,1,0,0,1-.52-1.31l10-23.22a1,1,0,1,1,1.84.79l-10,23.22A1,1,0,0,1,14.55,96Z" />
        <path className="cls-3" d="M47.4,96a1,1,0,0,1-.92-.6l-10-23.22A1,1,0,0,1,37,70.84a1,1,0,0,1,1.31.53l10,23.22a1,1,0,0,1-.52,1.31A.91.91,0,0,1,47.4,96Z" />
        <circle className="cls-5" cx="16.62" cy="14.82" r="7.64" />
        <path className="cls-4" d="M16.62,23.46a8.64,8.64,0,1,1,8.64-8.64A8.65,8.65,0,0,1,16.62,23.46Zm0-15.28a6.64,6.64,0,1,0,6.64,6.64A6.64,6.64,0,0,0,16.62,8.18Z" />
        <path className="cls-3" d="M98,52.35H60.42a1,1,0,0,1,0-2H98a1,1,0,0,1,0,2Z" />
        <path className="cls-3" d="M90.21,95.93a1,1,0,0,1-1-1V52a1,1,0,0,1,2,0v43A1,1,0,0,1,90.21,95.93Z" />
        <path className="cls-5" d="M72.89,38h9.56a0,0,0,0,1,0,0V47.8a.92.92,0,0,1-.92.92H73.81a.92.92,0,0,1-.92-.92V38A0,0,0,0,1,72.89,38Z" />
        <path className="cls-4" d="M81.52,49.72H73.81a1.92,1.92,0,0,1-1.92-1.93V38a1,1,0,0,1,1-1h9.56a1,1,0,0,1,1,1v9.82A1.93,1.93,0,0,1,81.52,49.72Zm-7.63-2h7.56V39H73.89Z" />
        <path className="cls-4" d="M72.89,46.79H70.32a2.16,2.16,0,0,1-2.15-2.16V42.06a2.16,2.16,0,0,1,2.15-2.16h2.57a1,1,0,0,1,1,1v4.89A1,1,0,0,1,72.89,46.79ZM70.32,41.9a.16.16,0,0,0-.15.16v2.57a.16.16,0,0,0,.15.16h1.57V41.9Z" />
        <path className="cls-4" d="M54,89.69l1.61,4.65,11.3-3.74-.48-1.42a3.3,3.3,0,0,0-4.11-2.1Z" />
        <path className="cls-3" d="M55.57,95.34a1,1,0,0,1-1-.67L53,90a1,1,0,0,1,.64-1.28L62,86.13a4.31,4.31,0,0,1,5.36,2.73l.48,1.42a1,1,0,0,1-.63,1.27L55.88,95.29A1.12,1.12,0,0,1,55.57,95.34Zm-.33-5,.95,2.74L65.6,90l-.16-.47A2.3,2.3,0,0,0,62.58,88Z" />
        <path className="cls-4" d="M70.07,75.54l3.22,3.71,9.09-7.7-1-1.14a3.3,3.3,0,0,0-4.6-.4Z" />
        <path className="cls-3" d="M73.29,80.25a1,1,0,0,1-.75-.35l-3.22-3.71a1,1,0,0,1-.24-.74,1,1,0,0,1,.36-.69l6.74-5.52a4.32,4.32,0,0,1,6,.52l1,1.14a1,1,0,0,1,.24.73,1,1,0,0,1-.35.69L73.94,80A1,1,0,0,1,73.29,80.25ZM71.5,75.66l1.9,2.19L81,71.44l-.32-.38a2.31,2.31,0,0,0-3.2-.28Z" />
        <path className="cls-4" d="M76.32,36.54a1,1,0,0,1-.76-1.65,1.44,1.44,0,0,0,.07-1.26,9.32,9.32,0,0,0-.33-.88,8.43,8.43,0,0,1-.46-1.34,3.09,3.09,0,0,1,.79-3.12,1,1,0,0,1,1.41.09A1,1,0,0,1,77,29.8,1.22,1.22,0,0,0,76.78,31a7.27,7.27,0,0,0,.37,1,10,10,0,0,1,.4,1.08,3.33,3.33,0,0,1-.47,3.12A1,1,0,0,1,76.32,36.54Z" />
        <path className="cls-4" d="M80.73,35.1a1.11,1.11,0,0,1-.46-.11,1,1,0,0,1-.42-1.35A2.16,2.16,0,0,0,79.91,32c-.1-.33-.24-.65-.37-1a10.51,10.51,0,0,1-.5-1.37,3.7,3.7,0,0,1,.46-3.22,1,1,0,0,1,1.59,1.22A1.78,1.78,0,0,0,81,29.11a8.78,8.78,0,0,0,.42,1.13c.15.37.31.75.43,1.13a4.11,4.11,0,0,1-.2,3.19A1,1,0,0,1,80.73,35.1Z" />
      </svg>
    ),
    text: 'Sveriges butiker vid dina fingertoppar',
    key: 'convinience',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="quality"
      >
        <title>kvalitet</title>
        <polygon className="cls-2" points="27.83 56.06 13.62 88.53 27.09 83.18 34.66 96.47 47.58 64.37 27.83 56.06" />
        <path className="cls-3" d="M34.66,97.47a1,1,0,0,1-.87-.5L26.65,84.43,14,89.46a1,1,0,0,1-1.09-.23,1,1,0,0,1-.2-1.1L26.91,55.66a1,1,0,0,1,1.31-.52L48,63.45a1,1,0,0,1,.54,1.29L35.59,96.84a1,1,0,0,1-.87.63ZM27.09,82.18a1,1,0,0,1,.87.51L34.5,94.18,46.28,64.91,28.35,57.37,15.51,86.71l11.21-4.46A1.21,1.21,0,0,1,27.09,82.18Z" />
        <polygon className="cls-2" points="72.17 56.06 86.38 88.53 72.91 83.18 65.34 96.47 52.42 64.37 72.17 56.06" />
        <path className="cls-3" d="M65.34,97.47h-.06a1,1,0,0,1-.86-.63L51.5,64.74A1,1,0,0,1,52,63.45l19.74-8.31a1,1,0,0,1,1.31.52L87.3,88.13a1,1,0,0,1-.2,1.1,1,1,0,0,1-1.09.23l-12.66-5L66.21,97A1,1,0,0,1,65.34,97.47ZM53.72,64.91,65.5,94.18,72,82.69a1,1,0,0,1,1.24-.44l11.21,4.46L71.65,57.37Z" />
        <path className="cls-4" d="M54.45,5h0A6.54,6.54,0,0,1,65.3,8.47h0a6.54,6.54,0,0,0,7.17,5.21h0a6.55,6.55,0,0,1,6.71,9.23h0a6.54,6.54,0,0,0,2.74,8.43h0a6.55,6.55,0,0,1,0,11.41h0a6.55,6.55,0,0,0-2.74,8.44h0a6.55,6.55,0,0,1-6.71,9.23h0a6.54,6.54,0,0,0-7.17,5.21h0a6.54,6.54,0,0,1-10.85,3.52h0a6.56,6.56,0,0,0-8.87,0h0a6.54,6.54,0,0,1-10.85-3.52h0a6.54,6.54,0,0,0-7.17-5.21h0a6.55,6.55,0,0,1-6.71-9.23h0a6.55,6.55,0,0,0-2.74-8.44h0a6.55,6.55,0,0,1,0-11.41h0a6.54,6.54,0,0,0,2.74-8.43h0a6.55,6.55,0,0,1,6.71-9.23h0a6.54,6.54,0,0,0,7.17-5.21h0A6.54,6.54,0,0,1,45.58,5h0A6.56,6.56,0,0,0,54.45,5Z" />
        <path className="cls-3" d="M41.18,71.89a7.6,7.6,0,0,1-7.43-6.06,5.58,5.58,0,0,0-6.08-4.42,7.55,7.55,0,0,1-7.73-10.64,5.57,5.57,0,0,0-2.32-7.15,7.55,7.55,0,0,1,0-13.15,5.56,5.56,0,0,0,2.32-7.14,7.55,7.55,0,0,1,7.73-10.64,5.57,5.57,0,0,0,6.08-4.42A7.55,7.55,0,0,1,46.26,4.21a5.57,5.57,0,0,0,7.51,0A7.55,7.55,0,0,1,66.28,8.27a5.58,5.58,0,0,0,6.08,4.42,7.55,7.55,0,0,1,7.73,10.64,5.56,5.56,0,0,0,2.32,7.14,7.55,7.55,0,0,1,0,13.15,5.57,5.57,0,0,0-2.32,7.15,7.55,7.55,0,0,1-7.73,10.64,5.57,5.57,0,0,0-6.08,4.42,7.55,7.55,0,0,1-12.51,4.06,5.57,5.57,0,0,0-7.51,0A7.48,7.48,0,0,1,41.18,71.89ZM28.3,59.37a7.6,7.6,0,0,1,7.41,6.06,5.56,5.56,0,0,0,9.2,3,7.58,7.58,0,0,1,10.22,0,5.55,5.55,0,0,0,9.19-3,7.6,7.6,0,0,1,8.27-6,5.55,5.55,0,0,0,5.68-7.82,7.59,7.59,0,0,1,3.16-9.72,5.55,5.55,0,0,0,0-9.66,7.59,7.59,0,0,1-3.16-9.72,5.54,5.54,0,0,0-5.68-7.82,7.6,7.6,0,0,1-8.27-6,5.55,5.55,0,0,0-9.19-3h0a7.58,7.58,0,0,1-10.22,0,5.56,5.56,0,0,0-9.2,3,7.59,7.59,0,0,1-8.27,6,5.54,5.54,0,0,0-5.68,7.82,7.59,7.59,0,0,1-3.16,9.72,5.55,5.55,0,0,0,0,9.66,7.59,7.59,0,0,1,3.16,9.72,5.55,5.55,0,0,0,5.68,7.82A8.43,8.43,0,0,1,28.3,59.37ZM54.45,5h0Z" />
        <circle className="cls-5" cx="50.65" cy="37.69" r="18.25" />
        <path className="cls-3" d="M50,59.25a22.2,22.2,0,1,1,22.19-22.2A22.23,22.23,0,0,1,50,59.25Zm0-42.4a20.2,20.2,0,1,0,20.19,20.2A20.22,20.22,0,0,0,50,16.85Z" />
        <path className="cls-4" d="M45,48.44a2.06,2.06,0,0,1-1.09-.32,2,2,0,0,1-.94-1.95l.57-5.46L39.3,37.09a2.05,2.05,0,0,1,.91-3.56l5.37-1.14,2.15-5.1a2,2,0,0,1,3.66-.24l2.75,4.76,0,0,5.48.44A2,2,0,0,1,61,35.68l-3.68,4.09,1.26,5.39a2,2,0,0,1-2.82,2.34l-5-2.23L46,48.14A2.06,2.06,0,0,1,45,48.44Zm4.71-20.39-2.19,5.08A2.06,2.06,0,0,1,46,34.35l-5.38,1.14,4.16,3.66a2.05,2.05,0,0,1,.7,1.77l-.58,5.46,4.77-2.82a2.06,2.06,0,0,1,1.9-.12l5,2.24-1.21-5.4a2,2,0,0,1,.47-1.85l3.68-4.09L54,33.83a2.08,2.08,0,0,1-1.61-1Z" />
      </svg>
    ),
    text: 'Varumärken med kvalitétsgaranti',
    key: 'quality',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="customer-satisfaction"
      >
        <title>kundnöjdhet</title>
        <path className="cls-2" d="M67.75,82.59H40.16A3.19,3.19,0,0,1,37,79.39V43.14A3.19,3.19,0,0,1,40.16,40H90.45a3.19,3.19,0,0,1,3.2,3.19V79.39a3.2,3.2,0,0,1-3.2,3.2H85.39a1.06,1.06,0,0,0-1.06,1.06V94.14a2.13,2.13,0,0,1-3.67,1.47L68.52,82.91A1.06,1.06,0,0,0,67.75,82.59Z" />
        <path className="cls-3" d="M82.2,97.27a3.1,3.1,0,0,1-2.26-1L67.75,83.59H40.16A4.2,4.2,0,0,1,36,79.39V43.14A4.2,4.2,0,0,1,40.16,39H90.45a4.2,4.2,0,0,1,4.2,4.19V79.39a4.2,4.2,0,0,1-4.2,4.2H85.39l-.06,10.55a3.12,3.12,0,0,1-2,2.91A3.19,3.19,0,0,1,82.2,97.27ZM40.16,41A2.19,2.19,0,0,0,38,43.14V79.39a2.2,2.2,0,0,0,2.19,2.2H67.75a2.06,2.06,0,0,1,1.5.64L81.39,94.92a1.08,1.08,0,0,0,1.23.27,1.11,1.11,0,0,0,.71-1V83.65a2.06,2.06,0,0,1,2.06-2.06h5.06a2.21,2.21,0,0,0,2.2-2.2V43.14A2.2,2.2,0,0,0,90.45,41Z" />
        <path className="cls-4" d="M29.82,52H57.41A3.19,3.19,0,0,0,60.6,48.8V12.55a3.19,3.19,0,0,0-3.19-3.2H7.12a3.19,3.19,0,0,0-3.19,3.2V48.8A3.19,3.19,0,0,0,7.12,52h5.06a1.06,1.06,0,0,1,1.06,1.07V63.55A2.13,2.13,0,0,0,16.91,65l12.14-12.7A1.07,1.07,0,0,1,29.82,52Z" />
        <path className="cls-3" d="M15.38,66.68a3,3,0,0,1-1.17-.23,3.1,3.1,0,0,1-2-2.9V53.06L7.12,53A4.2,4.2,0,0,1,2.93,48.8V12.55a4.2,4.2,0,0,1,4.19-4.2H57.41a4.2,4.2,0,0,1,4.19,4.2V48.8A4.2,4.2,0,0,1,57.41,53H29.82l-.05,0L17.63,65.71A3.11,3.11,0,0,1,15.38,66.68ZM7.12,10.35a2.2,2.2,0,0,0-2.19,2.2V48.8A2.19,2.19,0,0,0,7.12,51h5.06a2.07,2.07,0,0,1,2.06,2.07V63.55a1.11,1.11,0,0,0,.71,1,1.13,1.13,0,0,0,1.24-.27l12.14-12.7A2.06,2.06,0,0,1,29.82,51H57.41A2.19,2.19,0,0,0,59.6,48.8V12.55a2.2,2.2,0,0,0-2.19-2.2Z" />
        <path className="cls-4" d="M80.87,63.76v1a2,2,0,0,1-2,2h-.68A1.84,1.84,0,0,1,80,68.64v1.11a1.84,1.84,0,0,1-1.84,1.85h-.82a1.75,1.75,0,0,1,1.84,1.64v1.47a1.76,1.76,0,0,1-1.84,1.64s-4.51.42-8.11.42-7.59-.42-7.59-.42a1.52,1.52,0,0,1-1.61-1.44v-.78a2,2,0,0,1-2,2H53.8a2,2,0,0,1-2-2V58.91a2,2,0,0,1,2-2h4.27a2,2,0,0,1,2,2v1.57s.15-3.84,1-4.84c1.49-1.87,3.18-1.12,4.92-4.88.78-1.67,1.42-3,1.9-3.92a2.12,2.12,0,0,1,2.74-1l1.14.49a3.25,3.25,0,0,1,1.42,4.58l-2.39,4.73a.89.89,0,0,0,.79,1.27H78a2,2,0,0,1,2,2v.86a2,2,0,0,1-2,2h.83A2,2,0,0,1,80.87,63.76Z" />
        <polyline className="cls-4" points="76.63 61.76 77.26 61.76 78.04 61.76" />
        <polyline className="cls-4" points="75.77 71.6 76.63 71.6 77.37 71.6" />
        <polyline className="cls-4" points="76.4 66.8 77.96 66.8 78.19 66.8" />
        <line className="cls-4" x1="78.04" y1="61.76" x2="77.26" y2="61.76" />
        <line className="cls-4" x1="77.37" y1="71.6" x2="76.63" y2="71.6" />
        <line className="cls-4" x1="78.19" y1="66.8" x2="77.96" y2="66.8" />
        <rect className="cls-5" x="52.03" y="57.22" width="8.12" height="18.91" rx="1.96" />
        <path className="cls-5" d="M58.19,77.14H54a3,3,0,0,1-3-3v-15a3,3,0,0,1,3-3h4.2a3,3,0,0,1,3,3v15A3,3,0,0,1,58.19,77.14ZM54,58.22a1,1,0,0,0-1,1v15a1,1,0,0,0,1,1h4.2a1,1,0,0,0,1-1v-15a1,1,0,0,0-1-1Z" />
        <path className="cls-5" d="M77.87,63H76.48a1,1,0,0,1,0-2h1.39a1,1,0,0,0,1-1v-.83a1,1,0,0,0-1-1h-6.4a1.85,1.85,0,0,1-1.59-.89,1.87,1.87,0,0,1-.08-1.82l2.34-4.65a2.55,2.55,0,0,0,.17-2,1.88,1.88,0,0,0-1.06-1.12l-1.12-.48a1.1,1.1,0,0,0-1.42.51c-.59,1.15-1.21,2.44-1.85,3.83a7.4,7.4,0,0,1-3.53,3.9,4.33,4.33,0,0,0-1.45,1.11,12,12,0,0,0-.73,4.18,1,1,0,0,1-1,1,1,1,0,0,1-1-1c0-1,.24-4.2,1.17-5.36a6.15,6.15,0,0,1,2-1.59,5.41,5.41,0,0,0,2.69-3c.66-1.41,1.29-2.72,1.88-3.89a3.11,3.11,0,0,1,4-1.45l1.12.48a4,4,0,0,1,2.15,2.28,4.57,4.57,0,0,1-.26,3.61l-2.25,4.46h6.19a3,3,0,0,1,3,3V60A3,3,0,0,1,77.87,63Z" />
        <path className="cls-5" d="M69.22,77.77c-3.4,0-7.06-.36-7.54-.41a2.51,2.51,0,0,1-2.53-2.42V72.6a1,1,0,0,1,2,0v2.34a.53.53,0,0,0,.58.42h.11s3.92.41,7.38.41,7.85-.4,7.89-.41c.54,0,.91-.29.91-.63V73.29a.75.75,0,0,0-.81-.62H75.63a1,1,0,0,1,0-2h1.58A2.73,2.73,0,0,1,80,73.29v1.44a2.73,2.73,0,0,1-2.81,2.63C77.12,77.37,72.77,77.77,69.22,77.77Z" />
        <path className="cls-5" d="M78.69,68H76.26a1,1,0,0,1,0-2h2.43a1,1,0,0,0,1-1V64a1,1,0,0,0-1-1H77.1a1,1,0,0,1,0-2h1.59a3,3,0,0,1,3,3v1A3,3,0,0,1,78.69,68Z" />
        <path className="cls-5" d="M78,72.67H76.48a1,1,0,0,1,0-2H78a.82.82,0,0,0,.82-.82V68.76A.82.82,0,0,0,78,68h-.23a1,1,0,0,1,0-2H78a2.82,2.82,0,0,1,2.82,2.81v1.09A2.82,2.82,0,0,1,78,72.67Z" />
        <path className="cls-5" d="M32.36,44.18c-5.05,0-9.49-2.85-10.81-6.93a1,1,0,0,1,1.91-.61c1.05,3.26,4.71,5.54,8.9,5.54s8-2.41,9-5.74a1,1,0,1,1,1.92.55C42,41.22,37.55,44.18,32.36,44.18Z" />
        <ellipse className="cls-5" cx="21.76" cy="23.67" rx="2.49" ry="4.76" />
        <ellipse className="cls-5" cx="43.27" cy="23.67" rx="2.49" ry="4.76" />
      </svg>
    ),
    text: 'Kundservice som bryr sig',
    key: 'customer-satisfaction',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="user-interface"
      >
        <title>användargränssnitt</title>
        <rect className="cls-2" x="3.89" y="8.65" width="33.69" height="57.99" rx="4.35" />
        <path className="cls-3" d="M37.58,58.65v3.64a4.36,4.36,0,0,1-4.35,4.36h-25a4.36,4.36,0,0,1-4.36-4.36V58.65Z" />
        <path className="cls-4" d="M33.23,67.65h-25a5.36,5.36,0,0,1-5.36-5.36V13A5.36,5.36,0,0,1,8.25,7.65h25A5.36,5.36,0,0,1,38.58,13V62.29A5.36,5.36,0,0,1,33.23,67.65Zm-25-58A3.37,3.37,0,0,0,4.89,13V62.29a3.37,3.37,0,0,0,3.36,3.36h25a3.36,3.36,0,0,0,3.35-3.36V13a3.36,3.36,0,0,0-3.35-3.35Z" />
        <path className="cls-4" d="M37.58,17.31H3.89a1,1,0,1,1,0-2H37.58a1,1,0,1,1,0,2Z" />
        <path className="cls-4" d="M37.58,59.65H3.89a1,1,0,0,1,0-2H37.58a1,1,0,0,1,0,2Z" />
        <path className="cls-4" d="M15.38,13.79h-.25a1,1,0,0,1,0-2h.25a1,1,0,1,1,0,2Z" />
        <path className="cls-4" d="M25.92,13.79h-6.5a1,1,0,0,1,0-2h6.5a1,1,0,0,1,0,2Z" />
        <rect className="cls-3" x="17.1" y="24.73" width="78.42" height="56.46" rx="2.93" />
        <path className="cls-2" d="M95.52,72.31v6a2.92,2.92,0,0,1-2.93,2.93H20a2.92,2.92,0,0,1-2.94-2.93v-6Z" />
        <path className="cls-4" d="M92.59,82.2H20a3.94,3.94,0,0,1-3.93-3.93V27.66A3.94,3.94,0,0,1,20,23.73H92.59a3.94,3.94,0,0,1,3.93,3.93V78.27A3.93,3.93,0,0,1,92.59,82.2ZM20,25.73a1.94,1.94,0,0,0-1.93,1.93V78.27A1.94,1.94,0,0,0,20,80.2H92.59a1.94,1.94,0,0,0,1.93-1.93V27.66a1.94,1.94,0,0,0-1.93-1.93Z" />
        <path className="cls-4" d="M94.88,73.21H17.53a1,1,0,0,1,0-2H94.88a1,1,0,0,1,0,2Z" />
        <path className="cls-4" d="M44.54,93.4a1.11,1.11,0,0,1-.49-.12,1,1,0,0,1-.38-1.37l6.12-11a1,1,0,1,1,1.75,1l-6.13,11A1,1,0,0,1,44.54,93.4Z" />
        <path className="cls-4" d="M66.7,93.4a1,1,0,0,1-.87-.51l-6.13-11a1,1,0,1,1,1.75-1l6.12,11a1,1,0,0,1-.38,1.37A1.11,1.11,0,0,1,66.7,93.4Z" />
        <path className="cls-4" d="M71.54,93.65H39.83a1,1,0,0,1,0-2H71.54a1,1,0,0,1,0,2Z" />
        <circle className="cls-4" cx="56.31" cy="76.8" r="1.32" />
        <polyline className="cls-3" points="95.38 35.12 83.69 35.12 83.69 31.85 75.9 31.85 71.46 35.17 17.16 35.17" />
        <path className="cls-4" d="M71.46,36.17H17.16a1,1,0,1,1,0-2h54l4.17-3.12a1,1,0,0,1,.6-.2h7.79a1,1,0,0,1,1,1v2.27H95.38a1,1,0,0,1,0,2H83.69a1,1,0,0,1-1-1V32.85H76.23L72.06,36A1,1,0,0,1,71.46,36.17Z" />
        <circle className="cls-4" cx="24.92" cy="30.53" r="1.32" />
        <circle className="cls-4" cx="30.91" cy="30.53" r="1.32" />
        <circle className="cls-4" cx="36.91" cy="30.53" r="1.32" />
        <rect className="cls-3" x="26.15" y="42.45" width="22.65" height="22.65" />
        <path className="cls-5" d="M48.8,66.1H26.15a1,1,0,0,1-1-1V42.45a1,1,0,0,1,1-1H48.8a1,1,0,0,1,1,1V65.1A1,1,0,0,1,48.8,66.1Zm-21.65-2H47.8V43.45H27.15Z" />
        <path className="cls-5" d="M48.8,60.64,43.12,55a1,1,0,0,0-1.48,0L31.46,65.14" />
        <path className="cls-5" d="M26.44,59.36l5.87-5.88a1.05,1.05,0,0,1,1.47,0l3.93,3.93a1,1,0,0,0,1.48,0L41.65,55a1.05,1.05,0,0,1,1.47,0l5.36,5.27a1.06,1.06,0,0,1,.32.74v3.1a1,1,0,0,1-1,1H27.19a1.05,1.05,0,0,1-1.05-1v-4A1,1,0,0,1,26.44,59.36Z" />
        <path className="cls-5" d="M86.77,44.73H59.09a1,1,0,0,1,0-2H86.77a1,1,0,0,1,0,2Z" />
        <path className="cls-5" d="M86.77,50.49H59.09a1,1,0,0,1,0-2H86.77a1,1,0,0,1,0,2Z" />
        <path className="cls-5" d="M73.76,65H58.9a1,1,0,0,1-1-1V56.21a1,1,0,0,1,1-1H73.76a1,1,0,0,1,1,1V64A1,1,0,0,1,73.76,65ZM59.9,63H72.76V57.21H59.9Z" />
        <path className="cls-5" d="M86.77,65H78.94a1,1,0,0,1-1-1V56.21a1,1,0,0,1,1-1h7.83a1,1,0,0,1,1,1V64A1,1,0,0,1,86.77,65Zm-6.83-2h5.83V57.21H79.94Z" />
      </svg>
    ),
    text: 'Bekväm och smidig upplevelse',
    key: 'user-interface',
  },
]

const Ambition = () => (
  <section className="ambition-section">
    <div className="container">
      <div className="ambition-section__first-sentence">
        Om Jiroy
      </div>
      <div className="ambition-section__second-sentence">
        Tillsammans skapar vi en enkel, smart och hållbar marknadsplats
      </div>
      <div className="ambition-section__parts">
        {parts.map(({ icon, text, key }) => (
          <div className="ambition-section__part" key={key}>
            <div className="ambition-section__part__icon">{icon}</div>
            <div className="ambition-section__part__text">{text}</div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Ambition
