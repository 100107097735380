/* eslint-disable max-len */
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import Stars from 'components/Stars/Stars'
import Location from 'icons/icons/location'

const shuffleArray = () => Math.random() - 0.5

const articles = [
  {
    id: 0,
    stars: 5,
    text: 'Livet blev enklare med Jiroy 😍👌',
    name: 'Farid Bou Khaled',
    location: 'SE',
  },
  {
    id: 1,
    stars: 5,
    text: 'Grymt bra sida där jag hittar ALLT på ett och samma ställe vilket underlättar min vardag att slippa googla runt. Bästa JIROY!! 😍💓',
    name: 'Pamela Josefsson',
    location: 'SE',
  },
  {
    id: 2,
    stars: 5,
    text: 'En riktig tidsbesparare 🕒. Jag kan hitta allt jag behöver på ett och samma ställe utan att behöva leta runt på olika hemsidor.',
    name: 'Oscar Eriksson',
    location: 'SE',
  },
  {
    id: 3,
    stars: 5,
    text: 'Det är verkligen smidigt att kunna jämföra olika butiker och produkter på Jiroy. Det sparar mig mycket tid och pengar.',
    name: 'Emma Karlsson',
    location: 'SE',
  },
  {
    id: 4,
    stars: 5,
    text: '🛍️ Jag hittar produkter från butiker som jag vanligtvis aldrig hade besökt. Det är riktigt smart att samla alla grymma butiker såhär.',
    name: 'Sofia Lundqvist',
    location: 'SE',
  },
  {
    id: 5,
    stars: 5,
    text: 'Jag har aldrig sett en sådan mångfald av butiker och varumärken på en och samma plats. Det är verkligen bekvämt att handla här.',
    name: 'Johanna Andersson',
    location: 'SE',
  },
  {
    id: 6,
    stars: 5,
    text: 'Jag älskar att shoppa via er! Ni har ett fantastiskt urval av butiker och produkter och jag har sparar mycket tid och får inspo.',
    name: 'Andreas Sjöberg',
    location: 'SE',
  },
  {
    id: 7,
    stars: 5,
    text: 'Det är som att ha en shoppinggata direkt i min dator. Skit bekvämt!',
    name: 'Mommo Talebi',
    location: 'SE',
  },
  {
    id: 8,
    stars: 5,
    text: 'Som en kräsen shoppare passar detta mig. Imponeras av kvalitetsbutiker och alla produkter som man vet har bättre kvalité än amazon.',
    name: 'Max Bergman',
    location: 'SE',
  },
  {
    id: 9,
    stars: 5,
    text: 'Er marknadsplats har verkligen blivit mitt go-to-ställe för all min shopping. Jag älskar att jag kan hitta allt jag behöver på ett och samma ställe.',
    name: 'Henrik Ekström',
    location: 'SE',
  },
  {
    id: 10,
    stars: 5,
    text: 'Jag älskar att shoppa, men att gå runt i butiker eller köpcentra känns inte längre lika självklart. Er shoppingsajt har en perfekt balans mellan variation och bekvämlighet.',
    name: 'Emma Persson',
    location: 'SE',
  },
  {
    id: 11,
    stars: 5,
    text: 'Jiroy har verkligen förenklat min shopping. Nu behöver jag inte längre åka runt till olika butiker eller varje hemsida. Allt jag behöver finns ju här!',
    name: 'Frida Berglund',
    location: 'SE',
  },
  {
    id: 12,
    stars: 5,
    text: 'Så smart idé 💡Blir intressant att följa hur det utvecklas. Får man investera? ;)',
    name: 'Martin Nilsson',
    location: 'SE',
  },
  {
    id: 13,
    stars: 5,
    text: 'Som en inbiten online-shoppare har jag provat många marknadsplatser, men er tar verkligen priset. Det är som att vara i shoppinghimmeln!',
    name: 'Isabella Müller',
    location: 'SE',
  },
  {
    id: 14,
    stars: 5,
    text: 'Asså alla bra butiker på ett ställe = Vad finns att inte gilla. Smart ju! 🤓',
    name: 'Hugo Dupont',
    location: 'SE',
  },
  {
    id: 15,
    stars: 5,
    text: 'Istället för att surfa runt på olika webbplatser kan jag hitta allt jag behöver på ett ställe. 🤩',
    name: 'Amelia Russo',
    location: 'SE',
  },
  {
    id: 16,
    stars: 5,
    text: 'Varför har detta inte gjorts förut? Saknar några features innan det blir helt perfekt! Typ cash back? 😜',
    name: 'Mia Li',
    location: 'SE',
  },
  {
    id: 17,
    stars: 5,
    text: 'Älskar er sida, det gör det så enkelt och smidigt i min shopping att hitta alla butiker på en sida! 👌',
    name: 'Albertina Uddh',
    location: 'SE',
  },
].sort(shuffleArray)

const TestimonialsCarousel = () => (
  <section>
    <Swiper
      breakpoints={{
        275: {
          slidesPerView: 1.2,
          spaceBetween: 16,
        },
        400: {
          slidesPerView: 1.8,
          spaceBetween: 16,
        },
        740: {
          slidesPerView: 3.2,
          spaceBetween: 16,
        },
        896: {
          slidesPerView: 4.2,
          spaceBetween: 16,
        },
        1100: {
          slidesPerView: 5.2,
          spaceBetween: 16,
        },
      }}
      spaceBetween={30}
      modules={[Pagination]}
      className="testimonials-carousel"
    >
      {articles.map((article) => (
        <SwiperSlide key={article.id}>
          <article className="testimonials-carousel__article">
            <div className="testimonials-carousel__stars">
              <Stars amount={article.stars} />
            </div>
            <p className="testimonials-carousel__paragraph">
              {article.text}
            </p>
            <div className="testimonials-carousel__reviewer">
              <div className="testimonials-carousel__reviewer__name">{article.name}</div>
              <div className="testimonials-carousel__reviewer__location">
                <Location className="testimonials-carousel__svg" />
                {article.location}
              </div>
            </div>
          </article>
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
)

export default TestimonialsCarousel
