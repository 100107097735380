const HeroText = () => (
  <div id="hero__text">
    <h1>
      <div>&#35;jiroy</div>
      <div>&#35;smartshopping</div>
    </h1>
    <div className="display__flex">
      <h2>+350 butiker</h2>
      <h2>
        +
        {Number(4000).toLocaleString('sv-SE')}
        {' '}
        brands
      </h2>
      <h2>Ett ställe</h2>
    </div>
  </div>
)

export default HeroText
